export default function () {
  const video = document.getElementById('video')
  // const fullBtn = document.getElementById('fullBtn')

  const audioToggle = document.getElementById('audioToggle')

  if (audioToggle) {
    audioToggle.addEventListener('change', e => {
      video.muted = !e.target.checked
    })
  }

  // if (fullBtn) {
  //   fullBtn.addEventListener('click', function () {
  //     document.getElementById('container').requestFullscreen()
  //   })
  // }
}