import onBtns from './src/muteBtn.js'
import * as THREE from 'three';
// import Stats from 'three/examples/jsm/libs/stats.module.js'
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js'
import { Interaction } from 'three.interaction'
import toScreenPosition from './src/toScreenPosition.js'
import works from './videos.js'

const isIPFS = process.env.CONTEXT === 'ipfs'

// load work by index
let workIndex = window.location.pathname.split('/').pop() - 1
workIndex = !isNaN(workIndex) && works[workIndex] ? workIndex : 0

let perspectiveCamera, orthographicCamera, controls, scene, renderer, geometry, material, interaction, video, animation, sphere, sphereTexture, updating, lastClick

// video src
const urlParams = new URL(window.location.href).searchParams
let src = urlParams.get('src') ? decodeURIComponent(urlParams.get('src')) : null
// ipfs deploy looks for local video file
src = isIPFS ? './video.mp4' : src
src = src || works[workIndex].video

// thumb src
let thumb = isIPFS ? './still.jpg' : 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/stills/water_tn.jpg'

// default camera
const params = {
  orthographicCamera: true
}

const frustumSize = 400;

const container = document.getElementById('container')
const playBtn = document.getElementById('playBtn')
const cameraInToggle = document.getElementById('cameraInToggle')

// go!
init();
animate();
addBalls();
onBtns();


function init() {
  
  // renderer

  renderer = new THREE.WebGLRenderer( { antialias: true } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );

  // cameras

  const aspect = window.innerWidth / window.innerHeight;
  const fov = urlParams.get('fov') ?? 140

  // perspectiveCamera = new THREE.PerspectiveCamera( 60, aspect, 1, 1000 );
  perspectiveCamera = new THREE.PerspectiveCamera( fov, aspect, 1, 1500 );
  perspectiveCamera.position.z = 500;

  orthographicCamera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, 1, 1000 );
  orthographicCamera.position.z = 500;
  // zoom out so sphere fully visible
  orthographicCamera.zoom = 0.38
  orthographicCamera.updateProjectionMatrix();

  // scene
  scene = new THREE.Scene();

  // interactions
  interaction = new Interaction(renderer, scene, perspectiveCamera)

  // world

  // video
  console.log('src', src)
  video = document.getElementById( 'video' );
  video.setAttribute('src', src)
  // video.src = src || works[workIndex].video
  video.load()
  video.play()

  // video sphere
  geometry = new THREE.SphereGeometry( 500, 60, 40 );
  // invert the geometry on the x-axis so that all of the faces point inward
  geometry.scale( - 1, 1, 1 );

  // apply video as texture
  sphereTexture = new THREE.VideoTexture( video );
  const sphereMaterial = new THREE.MeshBasicMaterial( { map: sphereTexture } );

  sphere = new THREE.Mesh( geometry, sphereMaterial );

  // sphere.name = 'sphere0'

  scene.add( sphere )
  
  // insert
  container.appendChild( renderer.domElement );

  // set camera
  cameraInToggle.checked = !params.orthographicCamera
  setCamera()
  
  // listen for toggle
  cameraInToggle.addEventListener('change', e => {
    params.orthographicCamera = !e.target.checked
    setCamera()
  })

  if (playBtn) {
    playBtn.addEventListener('click', () => {
      // play video from beginning with audio
      audioToggle.click()
      // video.muted = false
      video.currentTime = 0
      // go inside sphere
      cameraInToggle.click()
      // show controls
      document.body.classList.remove('intro')
    })
  }

  // on video ended: next video
  if (!isIPFS) {
    video.addEventListener('ended', () => changeVideo(true))  
  } else {
    video.setAttribute('loop', true)
  }
  
  
  // on video loaded (once):
  video.addEventListener('loadedmetadata', (e) => {
    // remove loading screen
    document.body.classList.remove('loading')
  }, { once: true })
  
  // on resize
  window.addEventListener('resize', onWindowResize );
  window.addEventListener('click', (e) => { lastClick = e })
  window.addEventListener('touchstart', (e) => { lastClick = e.touches[0] })

  sphereClickListeners()
}

function setCamera () {
  if (controls) {
    controls.dispose()
  }
  
  createControls( params.orthographicCamera ? orthographicCamera : perspectiveCamera )
}

function createControls( camera ) {
  const isOrtho = camera.type === 'OrthographicCamera'

  controls = new TrackballControls( camera, renderer.domElement );

  controls.noZoom = isOrtho ? true : false
  controls.noPan = true // isOrtho ? true : false
  
  controls.rotateSpeed = isOrtho ? 2.0 : 1.0
  controls.zoomSpeed = 1.2;
  controls.panSpeed = 0.8;
  //
  controls.maxDistance = 450; // set <= sphere size so no black circle appears...
  controls.minDistance = 1

  controls.keys = [ 'KeyA', 'KeyS', 'KeyD' ];
}


function onWindowResize() {
  const aspect = window.innerWidth / window.innerHeight;

  perspectiveCamera.aspect = aspect;
  perspectiveCamera.updateProjectionMatrix();

  orthographicCamera.left = - frustumSize * aspect / 2;
  orthographicCamera.right = frustumSize * aspect / 2;
  orthographicCamera.top = frustumSize / 2;
  orthographicCamera.bottom = - frustumSize / 2;
  orthographicCamera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

  controls.handleResize();
}


function animate() {
  animation = requestAnimationFrame( animate );
  controls.update();
  // stats.update();
  render();
}

function render() {
  const camera = ( params.orthographicCamera ) ? orthographicCamera : perspectiveCamera;
  renderer.render( scene, camera );
}


function pauseAnimation () {
  cancelAnimationFrame(animation)
}


function changeVideo (isNext) {
  if (!updating) {
    updating = true

    // change to
    workIndex = isNext ? getNextIndex() : getPrevIndex()
    
    // show loading screen
    document.body.classList.add('loading')
    
    // pause anim
    pauseAnimation()
      
    // on can play... (once)
    video.addEventListener('canplaythrough', () => {
      // remove loading screen
      setTimeout(() => document.body.classList.remove('loading'), 3000)
      
      animate()

      video.play()  
      
      updating = false
    }, { once: true })

    // load new video
    video.src = works[workIndex].video
    video.load()
    video.pause()
    video.currentTime = 0

    // update url
    history.pushState(null, '', `/${workIndex ? workIndex + 1 : ''}`)
  }
}


function getNextIndex () {
  return workIndex + 1 === works.length ? 0 : workIndex + 1
}

function getPrevIndex () {
  // two back so feels more random ?
  return workIndex - 2 < 0 ? works.length - 1 : workIndex - 2
}


function addBalls () {
  // Add balls >:)
  if (thumb) {
    for ( let i = 0; i < 2; i ++ ) {
      const geometry = new THREE.SphereGeometry( 25, 60, 40 );
      // // invert the geometry on the x-axis so that all of the faces point inward
      geometry.scale( - 1, 1, 1 );
      //

      // still
      const texture = new THREE.TextureLoader().load( thumb )
      const material = new THREE.MeshBasicMaterial( { map: texture } );
      //
      const orb = new THREE.Mesh( geometry, material );
      
      orb.name = i ? 'next' : 'prev'
      orb.position.x = ( Math.random() - 0.5 ) * 625; // 800?
      orb.position.y = ( Math.random() - 0.5 ) * 625; // 800?
      orb.position.z = ( Math.random() - 0.5 ) * 625; // 800?
      orb.updateMatrix();
      orb.matrixAutoUpdate = false;

      if (!isIPFS) {
        // click event
        let isClick
        orb.cursor = 'pointer'
        orb.on('mousedown', (e) => { isClick = true })
        orb.on('mousemove', () => { isClick = false })
        orb.on('mouseup', () => isClick && changeVideo(i))
        // tap event
        orb.on('touchstart', (e) => { isClick = e.data.originalEvent.touches.length === 1 })
        orb.on('touchmove', () => { isClick = false })
        orb.on('touchend', () => isClick && changeVideo(i))
      }      

      scene.add( orb );
    }
  }
}

function sphereClickListeners () {
  if (isIPFS) return
  let sphereClick
  let orbs
  let dpx = window.devicePixelRatio

  const onClick = (e) => {
    if (sphereClick && params.orthographicCamera && !document.body.classList.contains('intro')) {
      setTimeout(() => {
        let hit
        orbs = orbs || scene.children.filter(obj => obj.name.length)

        orbs.forEach(obj => {
          const pos = toScreenPosition(obj, orthographicCamera, renderer)
          // click/tap is within 20px
          if (
            Math.abs(pos.x/dpx - lastClick.clientX) < 20
            &&
            Math.abs(pos.y/dpx - lastClick.clientY) < 20
          ) {
            hit = obj
          }
        })

        // contact
        if (hit) {
          const isNext = hit.name === 'next'
          changeVideo(isNext)
        }

        lastClick = null
        sphereClick = null
      }, 50)
    }
  }

  // mouse
  sphere.on('mousedown', () => { sphereClick = true })
  sphere.on('mousemove', () => { sphereClick = false })
  sphere.on('mouseup', onClick)
  // touch
  sphere.on('touchstart', (e) => { sphereClick = e.data.originalEvent.touches.length === 1 })
  sphere.on('touchmove', () => { sphereClick = false })
  sphere.on('touchend', onClick)
}

