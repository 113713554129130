export default [
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/Chamber.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/WATER%20DUET_1.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/DRUM%20DUET%202_1.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/DOUBLE%20BASS_2.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/Dream%20Cycle%203.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/SLEEPER%20tues.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/Sofa%20Duet_2.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/STRING%20POLE%20-%20mask%20-%20v3.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  },
  {
    video: 'https://folia-bultheel.fra1.cdn.digitaloceanspaces.com/VIOLENT%20short.mp4',
    // thumb: 'https://folia-bultheel.fra1.digitaloceanspaces.com/stills/aqua-duet-1.jpg'
  }
]