import { Vector3 } from 'three'

export default function (obj, camera, renderer)
{
    var vector = new Vector3();

    var ctx = renderer.getContext()
    var widthHalf = 0.5*ctx.canvas.width;
    var heightHalf = 0.5*ctx.canvas.height;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);
    vector.project(camera);

    vector.x = ( vector.x * widthHalf ) + widthHalf;
    vector.y = - ( vector.y * heightHalf ) + heightHalf;

    return { 
        x: vector.x,
        y: vector.y
    };

}